<template>
    <nav class="navbar main">
      <div class="navbar-brand">
        <router-link to="/" class="logo">
          <figure class="image is-48x48">
            <img src="../assets/atelier-ies-logo-small.jpeg">
          </figure>
        </router-link>

        <a
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-menu"
          @click="showMobileMenu = !showMobileMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        class="navbar-menu"
        id="navbar-menu"
        v-bind:class="{'is-active': showMobileMenu}"
      >
        <div class="navbar-start">
          <div class="navbar-item">
            <form method="get" action="/">
              <div class="field has-addons">
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    placeholder="Zoeken"
                    name="query"
                  >
                </div>

                <div class="control">
                  <button class="button is-inverted">
                    <span class="icon">
                      <i class="fas fa-search"></i>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="navbar-end">

          <div class="navbar-item">
            <div class="buttons are-normal">
              <router-link to="/" class="button is-light"
                @click="showMobileMenu = !showMobileMenu"
                v-if="$store.state.isAuthenticated">
                <span class="icon">
                  <i class="fa-solid fa-circle-user fa-xl"></i>
                </span>
              </router-link>
              <router-link to="/" class="button is-light"
                @click="showMobileMenu = !showMobileMenu"
                v-else>
                <span class="icon">
                  <i class="fa-solid fa-right-to-bracket"></i>
                </span>
              </router-link>

              <router-link to="/" class="button is-success"
                @click="showMobileMenu = !showMobileMenu">
                <span class="icon"><i class="fas fa-shopping-cart"></i></span>
                <span>({{ cartTotalLength }})</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </template>

<script>
import axios from 'axios'

export default {
  name: 'NavBar',
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: [],
      },
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

    const token = this.$store.state.token

    if (token) {
      axios.defaults.headers.common['Authorization'] = 'token' + token
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  },
  mounted() {
    console.log(this.$store.state.cart)
    this.cart = this.$store.state.cart
    this.showMobileMenu = false
  },
  computed: {
    cartTotalLength() {
      let totalLength = 0

      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += parseInt(this.cart.items[i].quantity)
      }

      return totalLength
    },
  },
}
</script>

<style>
.navbar-item {
    color: #34495e;
}

.navbar-item.is-active {
    color: #4f6f8f;
}

.logo {
    padding: 10px;
}

.navbar.main {
    background-color: #d4e6f1;
}

.navbar.main .navbar-menu {
    background-color: #d4e6f1;
}

.navbar .navbar-burger {
    height: 68px;
    width: 68px;
}

.navbar .navbar-burger span {
    width: 22px;
    height: 2px;
    margin: 2px 0 2px 0;
}
</style>

