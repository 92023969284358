<template>
  <div class="cart-view">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title winkelwagen">Winkelwagen</h1>
      </div>

      <div class="column is-12 box">
        <table class="table is-fullwidth" v-if="cartTotalLength">
          <thead>
            <tr>
              <th>Product</th>
              <th>Q</th>
              <th>Totaal</th>
            </tr>
          </thead>

          <tbody>
            <CartItem
              v-for="item in cart.items"
              v-bind:key="item.product.id"
              v-bind:initialItem="item"
              v-on:removeFromCart="removeFromCart"
            />
          </tbody>
        </table>

        <p v-else>U heeft nog geen producten in uw winkelwagen...</p>
      </div>

      <div>
        <h2 class="subtitle">Totaal</h2>
        <strong>
          €{{ cartTotalPrice.toFixed(2) }}
        </strong>, {{ cartTotalLength }}
        <span v-if="cartTotalLength > 1">producten</span>
        <span v-else>product</span>
        <hr>

        <router-link
          to="/cart/check-out"
          class="button is-dark"
          v-if="cartTotalLength"
        >
          <span class="icon">
            <i class="fa-solid fa-credit-card"></i>
          </span>
          <span>Afrekenen</span>
        </router-link>

        <div class="button is-dark" v-else>
          <span class="icon">
            <i class="fa-solid fa-credit-card"></i>
          </span>
          <span>Afrekenen</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import CartItem from '@/components/CartItem.vue'

export default {
  name: 'CartView',
  components: {
    CartItem,
  },
  data() {
    return {
      cart: {
        items: [],
      },
    }
  },
  mounted() {
    this.cart = this.$store.state.cart
    document.title = 'Winkelwagen | atelier-ies'
  },
  methods: {
    removeFromCart(item) {
      this.cart.items = this.cart.items.filter(
          (i) => i.product.id !== item.product.id,
      )
    },
  },
  computed: {
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return acc += parseInt(curVal.quantity)
      }, 0)
    },
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return acc += curVal.product.price * parseInt(curVal.quantity)
      }, 0)
    },
  },
}
</script>

<style scoped>
.subtitle {
    color: #34495e;
    font-weight: bold;
}

strong {
    color: #34495e;
}

.cart-view{
    color: #34495e;
}
.title.winkelwagen{
    text-align: center;
    color: #34495e;
}
</style>
