<template>
  <div class="check-out">
    <div class="columns is-multiline">
      <div class="column is-8 is-offset-2 has-text-centered">
        <h1 class="title">Afrekenen</h1>
      </div>

      <div class="column is-8 is-offset-2 box">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Product</th>
              <th>Q</th>
              <th>Totaal</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in cart.items"
              v-bind:key="item.product.id"
            >
              <td>{{ item.product.name }}</td>
              <td>{{ item.quantity }}</td>
              <td>€{{ getItemTotal(item).toFixed(2) }}</td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>Totaal</td>
              <td>{{ cartTotalLength }}</td>
              <td>€{{ cartTotalPrice.toFixed(2) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="column is-8 is-offset-2 box">
        <h2 class="subtitle">Verzendgegevens</h2>

        <div class="columns is-multiline">
          <div class="column is-6 top">
            <div class="field">
              <div class="control">
                <input
                  placeholder="Voornaam"
                  type="text"
                  class="input"
                  aria-required="true"
                  autocomplete="given-name"
                  v-model="first_name"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  placeholder="Achternaam"
                  type="text"
                  class="input"
                  aria-required="true"
                  autocomplete="family-name"
                  v-model="last_name"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  placeholder="E-mail"
                  type="email"
                  class="input"
                  aria-required="true"
                  autocomplete="email"
                  v-model="email"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  placeholder="Telefoonnummer (optioneel)"
                  type="text"
                  class="input"
                  aria-required="false"
                  autocomplete="tel"
                  v-model="phone"
                >
              </div>
            </div>
          </div>

          <div class="column is-6 bot">
            <div class="field">
              <div class="control">
                <input
                  placeholder="Staat en huisnummer"
                  type="text"
                  class="input"
                  aria-required="true"
                  autocomplete="street-address"
                  v-model="street"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  placeholder="Postcode"
                  type="text"
                  class="input"
                  aria-required="true"
                  autocomplete="postal-code"
                  v-model="zip"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  placeholder="Land"
                  type="text"
                  class="input"
                  aria-required="true"
                  autocomplete="country-name"
                  v-model="country"
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <input
                  placeholder="Stad"
                  type="text"
                  class="input"
                  aria-required="true"
                  autocomplete="address-level2"
                  v-model="place"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="payment-info">
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>

          <button
            class="button is-dark"
            @click="submitForm"
            v-if="disableButton"
            disabled
          >
            Betalen
          </button>
          <button
            class="button is-dark"
            @click="submitForm"
            v-else
          >
            Betalen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CheckOut',
  data() {
    return {
      cart: {
        items: [],
      },
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      street: '',
      zip: '',
      country: '',
      place: '',
      errors: [],
    }
  },
  mounted() {
    document.title = 'Afrekenen | atelier-ies'

    this.cart = this.$store.state.cart
  },
  methods: {
    getItemTotal(item) {
      return parseInt(item.quantity) * item.product.price
    },
    submitForm() {
      this.$store.commit('setIsLoading', true)
      this.mollieCheckout()
    },
    async mollieCheckout() {
      const items = []

      for (let i = 0; i < this.cart.items.length; i++) {
        const item = this.cart.items[i]
        const obj = {
          product: item.product.id,
          quantity: parseInt(item.quantity),
          price: item.product.price * parseInt(item.quantity),
        }

        items.push(obj)
      }

      const data = {
        'first_name': this.first_name,
        'last_name': this.last_name,
        'email': this.email,
        'phone': this.phone,
        'street': this.street,
        'zip': this.zip,
        'country': this.country,
        'place': this.place,
        'items': items,
      }

      await axios
          .post('api/v1/checkout/', data)
          .then((response) => {
            this.$store.commit('setIsLoading', false)
            this.$store.commit('clearCart')
            window.location = response.data
          })
          .catch((error) => {
            this.$store.commit('setIsLoading', false)
            this.errors.push('Er is iets fout gegaan. '+
              'Probeer het alstublieft nogmaals')

            console.log(error)
          })
    },
  },
  computed: {
    disableButton() {
      return (
        this.first_name == '' ||
        this.last_name == '' ||
        this.email == '' ||
        this.street == '' ||
        this.zip == '' ||
        this.country == '' ||
        this.place == ''
      )
    },
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return acc += parseInt(curVal.quantity)
      }, 0)
    },
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return acc += curVal.product.price * parseInt(curVal.quantity)
      }, 0)
    },
  },
}
</script>

<style scoped>
.title {
    color: #34495e;
}

.subtitle {
    color: #34495e;
    font-weight: 600;
}

.table thead tr th, .table tfoot td, .table tbody td{
    color: #34495e;
}

.title.winkelwagen{
    text-align: center;
    color: #34495e;
}

.column.is-6.top {
  @media screen and (max-width: 768px) {
    padding-top: 18px;
  }
}
.column.is-6.bot {
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}
</style>
