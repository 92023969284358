<template>
  <tr>
    <td>
      <router-link
        :to="item.product.get_absolute_url"
        class="cart-item">
        {{ item.product.name }}
      </router-link>
    </td>
    <td>

        <div class="field has-addons">
          <div class="control">
            <span class="button is-small is-static">
              {{ item.quantity }}
            </span>
          </div>
          <div class="control">
            <button
              class="button is-small"
              @click="incrementQuantity(item)"
            >
              <span class="icon">
                <i class="fa-solid fa-plus"></i>
              </span>
            </button>
          </div>
          <div class="control">
            <button
              class="button is-small"
              @click="decrementQuantity(item)"
            >
              <span class="icon">
                <i class="fa-solid fa-minus"></i>
              </span>
            </button>
          </div>
        </div>
        <!-- <a @click="decrementQuantity(item)">-</a>
        <a @click="decrementQuantity(item)">+</a> -->
    </td>
    <td>€{{ getItemTotal(item).toFixed(2) }}</td>
    <td><button class="delete" @click="removeFromCart(item)"></button></td>
  </tr>
</template>

<script>
export default {
  name: 'CartItem',
  props: {
    initialItem: Object,
  },
  data() {
    return {
      item: this.initialItem,
    }
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * item.product.price
    },
    decrementQuantity(item) {
      if (item.quantity > 1) {
        item.quantity -= 1
        this.updateCart()
      }
    },
    incrementQuantity(item) {
      if (item.quantity < item.product.stock) {
        item.quantity += 1
        this.updateCart()
      }
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', item)
      this.updateCart()
    },
    updateCart() {
      localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
    },
  },
}
</script>

<style scoped>
.cart-item {
    color: #34495e;
    font-weight: 500;
}

.button.is-small.is-static {
    background-color: white;
    color: #34495e;
    font-weight: 700;
}
</style>
