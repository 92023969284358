<template>
  <div class="log-in">
    <div class="columns is-multiline">
      <div class="column is-6 is-offset-3 has-text-centered">
        <h1 class="title is-2">Log in</h1>
      </div>
      <div class="column is-6 is-offset-3 box">
        <form @submit.prevent="submitForm">
          <div class="field">
            <div class="control">
                <input
                  placeholder="E-mail"
                  type="name"
                  class="input"
                  v-model="email">
            </div>
          </div>

          <div class="field">
            <div class="control">
                <input
                  placeholder="Wachtwoord"
                  type="password"
                  class="input"
                  v-model="password">
            </div>
          </div>

          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>

          <div class="field">
            <div class="control has-text-centered">
              <button
              class="button is-dark"
              v-if="disableButton"
              disabled
            >
              Log in
            </button>
            <button
              class="button is-dark"
              v-else
            >
              Log in
            </button>
            </div>
          </div>

          <div class="has-text-centered">
            Of <router-link to="/sign-up">klik hier</router-link> om registreren
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LogIn',
  data() {
    return {
      email: '',
      password: '',
      errors: [],
    }
  },
  mounted() {
    document.title = 'Log In | atelier-ies'
  },
  methods: {
    async submitForm() {
      axios.defaults.headers.common['Authorization'] = ''

      localStorage.removeItem('token')

      const formData = {
        email: this.email,
        password: this.password,
      }
      await axios
          .post('/api/v1/token/login/', formData)
          .then((response) => {
            const token = response.data.auth_token

            this.$store.commit('setToken', token)

            axios.defaults.headers.common['Authorization'] = 'token' + token

            localStorage.setItem('token', token)

            const toPath = this.$route.query.to || '/my-account'

            this.$router.push(toPath)
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                if (error.response.data.hasOwnProperty(property)) {
                  this.errors
                      .push(`${property}: ${error.response.data[property]}`)
                }
              }
            } else if (error.message) {
              this.error
                  .push('Er is iets mis gegaan, '+
                    'probeer het alstublieft nogmaals')
            }
          })
    },
  },
  computed: {
    disableButton() {
      return (
        this.email == '' ||
        this.password == ''
      )
    },
  },
}
</script>

<style scoped>
.title {
    color: #34495e;
}

label {
    color: #34495e;
}

.column.is-4.is-offset-4.box {
  padding-top: 24px;
}

::placeholder {
  color: #34495e;
}
</style>
