<template>
    <div class="product-info">
        <div class="columns is-multiline">
            <div class="colum is-9">
                <figure class="image mb-6">
                    <img v-bind:src="product.get_image">
                </figure>
            </div>

            <div class="column is-3">
                <p class="title is-3">{{ product.name }}</p>

                <p class="subtitle is-5">{{ product.description }}</p>

                <p><strong>Prijs: </strong>€{{ product.price }}</p>
                <p><strong>Voorraad: </strong>{{ product.stock }}</p>

                <div class="field has-addons mt-6">
                  <div class="control">
                    <div class="select">
                      <select v-model="quantity">
                        <option
                          v-for="i in product.stock"
                          v-bind:key="i"
                        >{{ i }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="control">
                      <a
                        class="button is-dark"
                        @click="addToCart()"
                      >Toevoegen aan winkelwagen</a>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {throwStatement} from '@babel/types'
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
  name: 'ProductView',
  data() {
    return {
      product: {},
      quantity: 1,
    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      this.$store.commit('setIsLoading', true)

      const categorySlug = this.$route.params.category_slug
      const productSlug = this.$route.params.product_slug

      await axios
          .get(`/api/v1/products/${categorySlug}/${productSlug}`)
          .then((response) => {
            this.product = response.data

            document.title = this.product.name + ' | atelier-ies'
          })
          .catch((error) => {
            toast({
              message: 'Er is iets fout gegaan. Probeer het opnieuw.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          })

      this.$store.commit('setIsLoading', false)
    },
    addToCart() {
      if (isNaN(this.quantity)) {
        this.quantity = 1
      }

      const item = {
        product: this.product,
        quantity: parseInt(this.quantity),
      }
      let cartItem = null

      for (let i = 0; i < this.$store.state.cart.items.length; i++) {
        if (item.product.id == this.$store.state.cart.items[i].product.id) {
          cartItem = this.$store.state.cart.items[i]
        }
      }

      if (!cartItem) {
        this.$store.commit('addToCart', item)
      } else {
        const currQuantity = parseInt(cartItem.quantity)
        const newQuantity = parseInt(cartItem.quantity,
        ) + parseInt(item.quantity)
        const productStock = parseInt(item.product.stock)

        if (newQuantity <= productStock) {
          this.$store.commit('addToCart', item)
        } else if (currQuantity == productStock) {
          toast({
            message: 'Maximum aantal bereikt.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        } else if (newQuantity > productStock) {
          toast({
            message:
            'Kies een lager aantal, ' +
            'anders zit je winkelwagen boven de voorraad.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        } else {
          toast({
            message: 'Error!',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        }
      }
    },
  },
}
</script>

<style scoped>
.title {
    color: #34495e;
    font-weight: bold;
}
.subtitle {
    color: #34495e;
}

strong {
    color: #34495e;
}
</style>
