<template>
  <div class="my-account">
    <div class="columns is-multiline">
      <div class="column is-8 is-offset-2">
        <h1 class="title">Mijn account</h1>
      </div>

      <div class="column is-8 is-offset-2">
        <button class="button is-danger" @click="logout()">Log uit</button>
      </div>

      <hr>

      <div class="column is-8 is-offset-2">
        <h2 class="subtitle">Mijn bestellingen</h2>

        <OrderSummary
          v-for="order in orders"
          v-bind:key="order.id"
          v-bind:order="order"
        ></OrderSummary>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import OrderSummary from '@/components/OrderSummary.vue'

export default {
  name: 'MyAccount',
  components: {
    OrderSummary,
  },
  data() {
    return {
      orders: [],
    }
  },
  mounted() {
    document.title = 'Mijn account | atelier-ies'

    this.getMyOrders()
  },
  methods: {
    logout() {
      axios.defaults.headers.common['Authorization'] = ''

      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('userid')

      this.$store.commit('removeToken')

      this.$router.push('/')
    },
    async getMyOrders() {
      this.$store.commit('setIsLoading', true)

      await axios
          .get('/api/v1/orders/')
          .then((response) => {
            this.orders = response.data
          })
          .catch((error) => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },
  },
}
</script>
