<template>
  <div class="sign-up">
    <div class="columns is-multiline is-centered">
      <div class="column is-8 has-text-centered">
        <h1 class="title is-2">Registreren</h1>
      </div>

      <div class="column is-8 box">
        <form @submit.prevent="submitForm">
          <div class="columns is-multiline">
            <div class="column is-6 account is-hidden-tablet">
              <div class="field">
                <div class="control">
                  <input
                    placeholder="E-mail"
                    type="email"
                    class="input"
                    aria-required="true"
                    autocomplete="email"
                    v-model="email"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Wachtwoord"
                    type="password"
                    class="input"
                    aria-required="false"
                    v-model="password"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Herhaal wachtwoord"
                    type="password"
                    class="input"
                    aria-required="false"
                    v-model="password2"
                  >
                </div>
              </div>
            </div>
            <hr class="is-hidden-tablet">
            <div class="column is-6 name">
              <div class="field">
                <div class="control">
                  <input
                    placeholder="Voornaam"
                    type="text"
                    class="input"
                    aria-required="true"
                    autocomplete="given-name"
                    v-model="first_name"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Achternaam"
                    type="text"
                    class="input"
                    aria-required="true"
                    autocomplete="family-name"
                    v-model="last_name"
                  >
                </div>
              </div>

              <div class="field is-hidden-mobile">
                <div class="control">
                  <input
                    placeholder="E-mail"
                    type="email"
                    class="input"
                    aria-required="true"
                    autocomplete="email"
                    v-model="email"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Telefoonnummer (optioneel)"
                    type="text"
                    class="input"
                    aria-required="false"
                    autocomplete="tel"
                    v-model="phone"
                  >
                </div>
              </div>
            </div>

            <div class="column is-6 address">
              <div class="field">
                <div class="control">
                  <input
                    placeholder="Staat en huisnummer"
                    type="text"
                    class="input"
                    aria-required="true"
                    autocomplete="street-address"
                    v-model="street"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Postcode"
                    type="text"
                    class="input"
                    aria-required="true"
                    autocomplete="postal-code"
                    v-model="zip"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Land"
                    type="text"
                    class="input"
                    aria-required="true"
                    autocomplete="country-name"
                    v-model="country"
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    placeholder="Stad"
                    type="text"
                    class="input"
                    aria-required="true"
                    autocomplete="address-level2"
                    v-model="place"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-multiline is-hidden-mobile">
            <div class="column is-12 has-text-centered">
              Je logt in met je e-mail and je wachtwoord
            </div>
            <div class="column is-6">
              <div class="field">
                <div class="control">
                  <input
                    placeholder="Wachtwoord"
                    type="password"
                    class="input"
                    aria-required="true"
                    v-model="password"
                  >
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <div class="control">
                  <input
                    placeholder="Herhaal wachtwoord"
                    type="password"
                    class="input"
                    aria-required="true"
                    v-model="password2"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="payment-info">
            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>
          </div>

          <div class="center">
            <button
              class="button is-dark"
              v-if="disableButton"
              disabled
            >
              Registreren
            </button>
            <button
              class="button is-dark"
              v-else
            >
              Registreren
            </button>
          </div>
        </form>
        <div class="klik-hier has-text-centered">
          Of <router-link to="/log-in">klik hier</router-link> om in te loggen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
  name: 'SignUp',
  data() {
    return {
      email: '',
      password: '',
      password2: '',

      first_name: '',
      last_name: '',
      phone: '',
      street: '',
      zip: '',
      country: '',
      place: '',

      errors: [],
    }
  },
  mounted() {
    document.title = 'Registreren | atelier-ies'
  },
  methods: {
    submitForm() {
      this.errors = []

      if (this.email === '') {
        this.errors.push('- E-mailadres ontbreekt')
      }
      if (this.password === '') {
        this.errors.push('- Wachtwoord is te kort')
      }
      if (this.password !== this.password2) {
        this.errors.push('- Wachtworden komen niet overeen')
      }

      if (!this.errors.length) {
        const formData = {
          email: this.email,
          password: this.password,

          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.place,
          street: this.street,
          zip: this.zip,
          country: this.country,
          place: this.place,
        }

        axios
            .post('api/v1/users/', formData)
            .then((response) => {
              toast({
                message: 'Account aangemaakt, Log alsjeblieft in!',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })

              this.$router.push('/log-in')
            })
            .catch((error) => {
              if (error.response) {
                for (const property in error.response.data) {
                  if (error.response.data.hasOwnProperty(property)) {
                    if (Array.isArray(error.response.data[property])) {
                      error.response.data[property].forEach((m, i) => {
                        this.errors.push('- ' + m)
                      })
                    } else {
                      this.errors.push('- ' + error.response.data[property])
                    }
                  }
                }
              } else if (error.message) {
                this.error
                    .push('Er is iets mis gegaan, '+
                    'probeer het alstublieft nogmaals')
              }
            })
      }
    },
  },
  computed: {
    disableButton() {
      return (
        this.email == '' ||
        this.password == '' ||

        this.first_name == '' ||
        this.last_name == '' ||
        this.street == '' ||
        this.zip == '' ||
        this.country == '' ||
        this.place == ''
      )
    },
  },
}
</script>

<style scoped>
.title {
    color: #34495e;
}

label {
    color: #34495e;
}

span {
    color: #34495e;
}

a {
    color: #5d81a6;
}

.notification.is-danger {
    text-align: left;
    margin-bottom: 10px;
}

.center {
  text-align: center;
}
.klik-hier {
  padding: 10px 0 10px 5px
}

.column.is-6.account {
  padding-top: 18px;
  padding-bottom: 12px;
  /* border-bottom: 1px dotted #34495e; */
}

.column.is-6.name {
  @media screen and (max-width: 768px) {
    padding-top: 12px;
  }
}
.column.is-6.address {
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
}

hr {
  margin: 0;
}

::placeholder {
  color: #34495e;
}
</style>
