<template>
  <div class="category-view">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="is-size-2 has-text-centered">{{ category.name }}</h2>
      </div>

      <ProductBox
        v-for="product in category.products"
        v-bind:key="product.id"
        v-bind:product="product"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

import ProductBox from '@/components/ProductBox'

export default {
  name: 'CategoryView',
  components: {
    ProductBox,
  },
  data() {
    return {
      category: {
        products: [],
      },
    }
  },
  mounted() {
    this.getCategory()
  },
  watch: {
    $route(to, from) {
      if (to.name === 'CategoryView') {
        this.getCategory()
      }
    },
  },
  methods: {
    async getCategory() {
      const categorySlug = this.$route.params.category_slug

      this.$store.commit('setIsLoading', true)

      await axios
          .get(`/api/v1/products/${categorySlug}/`)
          .then((response) => {
            this.category = response.data

            document.title = this.category.name + ' | atelier-ies'
          })
          .catch((error) => {
            toast({
              message: 'Er is iets fout gegaan. Probeer het opnieuw.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          })

      this.$store.commit('setIsLoading', false)
    },
  },
}
</script>
