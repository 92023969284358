<template>
  <div class="succes-view">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Bedankt</h1>

        <p>Je ontvangt zo snel mogelijk een bevestiging e-mail</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccesView',
  mounted() {
    document.title = 'Succes | atelier-ies'
  },
  data() {
    return {
    }
  },
}
</script>
