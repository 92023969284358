import {createRouter, createWebHistory} from 'vue-router'

import store from '../store'

import TempHome from '../views/TempHome.vue'
import HomeView from '../views/HomeView.vue'
import ProductInfo from '../views/ProductInfo.vue'
import CategoryView from '../views/CategoryView.vue'
import SearchView from '../views/SearchView.vue'
import CartView from '../views/CartView.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import CheckOut from '../views/CheckOut.vue'
import SuccessView from '../views/SuccessView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: TempHome,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(
        /* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/search',
    name: 'SearchView',
    component: SearchView,
  },
  {
    path: '/cart',
    name: 'CartView',
    component: CartView,
  },
  {
    path: '/cart/check-out',
    name: 'CheckOut',
    component: CheckOut,
  },
  {
    path: '/cart/success',
    name: 'SuccessView',
    component: SuccessView,
  },
  {
    path: '/:category_slug/:product_slug',
    name: 'ProductInfo',
    component: ProductInfo,
  },
  {
    path: '/:category_slug',
    name: 'CategoryView',
    component: CategoryView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated) {
    next({name: 'LogIn', query: {to: to.path}})
  } else {
    next()
  }
})

export default router
