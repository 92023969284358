<template>
  <div class="search-view">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Resultaten</h1>

        <h2 class="is-size-5 has-text-gray">Zoek term: "{{ query }}"</h2>
      </div>

      <ProductBox
        v-for="product in products"
        v-bind:key="product.id"
        v-bind:product="product"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ProductBox from '@/components/ProductBox.vue'

export default {
  name: 'SearchView',
  components: {
    ProductBox,
  },
  data() {
    return {
      products: [],
      query: '',
    }
  },
  mounted() {
    document.title = 'Zoeken | atelier-ies'

    const uri = window.location.search.substring()
    const params = new URLSearchParams(uri)

    if (params.get('query')) {
      this.query = params.get('query')

      this.performSearch()
    }
  },
  methods: {
    async performSearch() {
      this.$store.commit('setIsLoading', true)

      await axios
          .post('api/v1/products/search/', {'query': this.query})
          .then((response) => {
            this.products = response.data
          })
          .catch((error) => {
            toast({
              message: 'Er is iets fout gegaan. Probeer het opnieuw.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          })

      this.$store.commit('setIsLoading', false)
    },
  },
}
</script>
