<template>
  <div class="columns">
    <div class="column is-12">
      <div class="box mb-4">
        <h1 class="is-size-4 mb-6">Bestelling #{{ order.id }}</h1>

        <h2 class="is-size-5">Producten</h2>

        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Product</th>
              <th>Q</th>
              <th>Totaal</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in order.items"
              v-bind:key="item.product.id"
            >
              <td>{{ item.product.name }}</td>
              <td>{{ item.product.quantity }}</td>
              <td>€{{ getItemTotal(item).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>

        <h2 class="is-size-6">Status: {{ order.status }}</h2>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    order: Object,
  },
  methods: {
    getItemTotal(item) {
      return parseInt(item.quantity) * item.product.price
    },
  },
}
</script>
