<template>
    <div class="home-view">
      <section class="hero is-medium is-dark mb-6">
          <div class="hero-body has-text-centered blok">
              <p class="title">
                  Atelier Ies gaat binnenkort live!
              </p>
          </div>
      </section>
    </div>
  </template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
  name: 'HomeView',
  data() {
    return {
      latestProducts: [],
    }
  },
  mounted() {
    this.getLatestProduct()

    document.title = 'Home | atelier-ies'
  },
  methods: {
    async getLatestProduct() {
      this.$store.commit('setIsLoading', true)
      await axios
          .get('/api/v1/latest-products/')
          .then((response) => {
            this.latestProducts = response.data
          })
          .catch((error) => {
            toast({
              message: 'De server is offline, sorry voor het ongemak!',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
          })
      this.$store.commit('setIsLoading', false)
    },
  },
}
</script>

<style>
.blok {
    background-color: #34495e;
    width: 100%;
}

.title {
    margin: 50px 0 50px 0;
}
</style>
